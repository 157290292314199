export enum TraceActionValues {
	/**
	 * 接口网络失败
	 */
	NetworkError = 'networkError',
	/**
	 * 接口成功
	 */
	Success = 'success',
	/**
	 * 请求成功但无结果
	 */
	NoResult = 'noResult',
	/**
	 * 接口服务失败
	 */
	FetchError = 'fetchError',
	/**
	 * 页面退出
	 */
	Break = 'break',
}

export type BlockBasicInfo = {
	/**
	 * 是否国际 0国内、1国际
	 */
	isInternational: string;
	/**
	 * 航程类型
	 */
	tripType: string;
	/**
	 * 舱位类型
	 */
	cabinType?: string; // TODO: 舱等类型，目前填写页拿不到舱等信息
	/**
	 * 航线
	 */
	ODRoutes: string;
	/**
	 * 乘客数量
	 */
	passengerCount?: string; // TODO: 乘客数量类型
	/**
	 *  品牌 'Ctrip'
	 */
	appSource: string;
	/**
	 * 客户端类型： 'Online',
	 */
	platform: string;
	/**
	 * 页面pageid
	 */
	pageId?: string;
	/*
	 * 前端、BFF、AGG排查用，预定流程不重查不变
	 */
	txid?: string;
	/**
	 * 自定义json字符串 如运营和产品需要的订单号需要加{"orderId","12123232271"}
	 */
	moreInfo?: string;
};
// readonly ["pop", "toast", "focus", "empty", "error"];
export enum BlockTypeValue {
	// 弹框
	Pop = 'pop',
	// Toast
	Toast = 'toast',
	// 锚定
	Focus = 'focus',
	// 无结果
	Empty = 'empty',
	// error（错误页，如无网络、业务强制阻断）
	Error = 'error',
}
// readonly ["block", "tip"];
export enum InterruptTypeValue {
	/**
	 * 强拦
	 */
	Block = 'block',
	/**
	 * 弱拦
	 */
	Tip = 'tip',
}

export enum TripTypeValue {
	ONE_WAY = 'OW',
	ROUND_TRIP = 'RT',
}

export enum MainTypeValue {
	Search = 'Search',
	RouteStrictSearch = 'RouteStrictSearch',
	BookingValidation = 'BookingValidation',
	PassengerCheck = 'PassengerCheck',
	ContactCheck = 'ContactCheck',
	XRelated = 'X-related',
	CreateOrder = 'CreateOrder',
	Reservation = 'Reservation',
	TimeOut = 'TimeOut',
	Other = 'Other',
}

export enum SubTypeValue {
	// 首页搜索缺失出发/到达城市
	MissingCity = 'MissingCity',
	// 出发到达机场相同
	SameAirport = 'SameAirport',
	// 相同出发到底城市
	SameCity= 'SameCity',
	// 城市错误（e.g 提示一程中需为国际城市）
	CityError = 'CityError',
	// 不支持特定航线
	NotSupportRoute= 'NotSupportRoute',
	// 日期格式有误，请检查'
	WrongDate = 'WrongDate',
	// 缺失出发日期
	MissingDate = 'MissingDate',
	// 出发日期不能早于今天
	ExpiredDate = 'ExpiredDate',
	// 返程日期早于去程日期
	DepartureDateLaterThanArrivalDate = 'DepartureDateLaterThanArrivalDate',
	// 国内多程超过2程
	ChinaMultiTripOverTwo = 'ChinaMultiTripOverTwo',
	// 多程的 后面一程的出发日期不能早于前面一程的出发日期
	MutiDepartDateLaterThanNextTrip = 'MutiDepartDateLaterThanNextTrip',
	// 紧急通知
	RedNotice = 'RedNotice',
	/**
	 * 伤残军警弹窗
	 */
	Army = 'Army',
	/**
	 * 国内目前仅支持两程查询弹窗
	 */
	MultiTripRestriction = 'MultiTripRestriction',
	// 反爬验证码拦截
	Captcha = 'Captcha',
	// // 留学生票
	// Student = 'Student',
	// // 满舱
	// FullCabin = 'FullCabin',
	// // 错误码
	// ErrorCode = 'ErrorCode',
	// // 极速中转
	// FastTransfer = 'FastTransfer',
	// 临近起飞
	DepartSoon = 'DepartSoon',
	// 抽奖弹窗
	Lottery = 'Lottery',
	// 列表页 页面停留超时
	ShoppingTimeOut = 'ShoppingTimeOut',
    // 空铁组合
    TrainAndFlight = 'TrainAndFlight', 
	// 旅套劣势提示弹窗
	TravelDisadvantageTip = 'TravelDisadvantageTip',
	// 多票组合
	SelfTransfer = 'SelfTransfer',
	// 大兴机场提醒
	PKAirports = 'PKAirports',
	// 查询无结果
	NoResult = 'NoResult',
	// // 中间页变价
	// PriceChange = 'PriceChange',
	// // 退改签无结果
	// RefundNoResult = 'RefundNoResult',
	// // 验舱验价满仓
	// FullSeat = 'FullSeat',
	// // 学生认证
	// StudentCheck = 'StudentCheck',
	// 未选择乘机人
	NoPsgSelected = 'NoPsgSelected',
	// // 旅途中乘客类型变化
	// PassengerTypeChange = 'PassengerTypeChange',
	// // 人数限制
	// TotalNumOverLimit = 'TotalNumOverLimit',
	// // 年龄限制
	// AgeLimit = 'AgeLimit',
	// // 下单人数与搜索人数不符
	// PassengerTypeDiffSearchType = 'PassengerTypeDiffSearchType',
	// // 未选择成人
	// 儿童/婴儿乘机需有年满18周岁的成人陪同，请添加成人乘机人
	NoAdultPsg = 'NoAdultPsg',
	// 成人儿童比例验证	（每位成人最多能带2位儿童）
	AdultChildRatio = 'AdultChildRatio',
	// // 国内港澳台同胞护照预订拦截提示
	// HK_TW_MCO_Passport = 'HK_TW_MCO_Passport',
	// // 非中国国籍使用护照缺少英文姓名
	// ForeignerWithoutEName = 'ForeignerWithoutEName',
	// // 未成年人需有18岁以上成年人为其投保。
	// MinorsInsuranceCheck = 'MinorsInsuranceCheck',
	// // 重复拼音
	// RepeatNamePinyin = 'RepeatNamePinyin',
	// // 重复姓名
	// RepeatName = 'RepeatName',
	// // 重复证件号
	// RepeatCard = 'RepeatCard',
	// // 姓名有误
	// ErrorName = 'ErrorName',
	// // 缺失姓名
	// MissingName = 'MissingName',
	// // 证件有效期缺少
	// MissingIDExpired = 'MissingIDExpired',
	// // 缺少性别
	// MissingGender = 'MissingGender',
	// // 缺少国籍
	// MissingNationality = 'MissingNationality',
	// // 国籍限制
	// NationalTips = 'NationalTips',
	// // 缺少出生日期
	// MissingBirthday = 'MissingBirthday',
	// // 缺少证件号
	// MissingID = 'MissingID',
	// // 信息不完整，缺失个人信息
	// InformationIncomplete = 'InformationIncomplete',
	// // 强拦，证件过期    弱拦，即将过期
	// ExpiredID = 'ExpiredID',
	// // 证件号码错误
	// IncorrectIDNumber = 'IncorrectIDNumber',
	// // 手机号缺失
	// MissingMobileNum = 'MissingMobileNum',
	// // 乘机人手机号错误
	// MobileNumLimit = 'MobileNumLimit',
	// // 人数大于余票
	// LeftTicketLimit = 'LeftTicketLimit',
	// // 仅1张，仅成人预定
	// AdultBookAlone = 'AdultBookAlone',
	// // 限几人预定，最小-最大
	// PassengerRateLimit = 'PassengerRateLimit',
	// // 乘机人定向政策
	// PassengerTarget = 'PassengerTarget',
	// // 春秋最大年龄限制
	// AgeRangeLimit = 'AgeRangeLimit',
	// // 证件类型限制
	// IDTypeLimit = 'IDTypeLimit',
	// // 未满14天的婴儿提示
	// InfantUnder14DaysLimit = 'InfantUnder14DaysLimit',
	// // 老年人出行提示
	// OldTips = 'OldTips',
	// 不支持购买婴儿票
	InfantNotAllowed = 'InfantNotAllowed',
	// // 不支持购买童票
	// ChildNotAllowed = 'ChildNotAllowed',
	// // 飞行期间婴儿变儿童
	// InfantBecomeChild = 'InfantBecomeChild',
	// // 飞行期间儿童变成人
	// ChildBecomeAdult = 'ChildBecomeAdult',
	// // 乘机人信息确认弹窗
	// PassengerConfirm = 'PassengerConfirm',
	// // 未成年人保护弹窗
	// MinorProtect = 'MinorProtect',
	// // 联系人手机号码不对
	// PhoneError = 'PhoneError',
	// // 联系人邮箱不对
	// EmailError = 'EmailError',
	// // 联系人航司会员姓名不对
	// NameError = 'NameError',
	// // 联系人航司会员证件号不对
	// CardNumError = 'CardNumError',
	// // 在美居住地所在州 不对
	// USCityError = 'USCityError',
	// // 在美居住详细地不对
	// USLocationError = 'USLocationError',
	// // 联系人邮编规则不符
	// ZipCodeError = 'ZipCodeError',
	// // 保险挽留
	// InsuranceCheck = 'InsuranceCheck',
	// // 服务包挽留
	// XRetain = 'XRetain',
	// // 优惠券添加失败
	// AddCouponFail = 'AddCouponFail',
	// // 优惠券添加成功
	// AddCouponSuccess = 'AddCouponSuccess',
	// // 优惠券不可用
	// CouponUnable = 'CouponUnable',
	// // 航司会员实名注册拦截
	// AirlineMemberRegister = 'AirlineMemberRegister',
	// // 航司会员注册却少手机号拦截
	// AirlineMemberPhoneError = 'AirlineMemberPhoneError',
	// // 重复订单
	// RepeatedOrder = 'RepeatedOrder',
}

export const classTypeMap = {
	Y_S: ['Economy', 'Premium'],
	C_F: ['Business', 'First'],
	C: ['Business'],
	F: ['First'],
	Y_S_C_F: ['ALL'],  // 这种类型只适用于阻塞埋点传值，业务场景还是对应['Economy', 'Business', 'First'],
	Y: ['Economy'],
	CF: ['Business', 'First'],
};
