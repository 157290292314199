import { echoTrace, EchoBehave } from '@ctrip/xtaro-component-echo-sdk-h5';
import { getIfScopeDomestic } from '@/src/sources/list/scope';
import { ubtError } from '@/src/sources/common/lightUbt';
import { getPageId } from '@/src/pages/list';
import { getTransactionIdFromGlobal } from '@/src/actions/common';
import store from '../stores/list';
import { BlockTypeValue, InterruptTypeValue, MainTypeValue, SubTypeValue, BlockBasicInfo, classTypeMap } from './types';
import {
	flightWaySelector,
	getMtSegListSelector,
	displayClassGradeSelector,
	displayPassengerSelector,
} from '../containers/list/selector/search';

/**
 *
 * @param mtSegListData
 * @returns LGW-SHA｜SHA-LGW
 */
const handleODRoutesData = (mtSegListData) => {
	const cityPairs = mtSegListData
		.valueSeq()
		.map((segment: any) => {
			const dCityCode = segment.get('dCityText')?.match(/\(([^()]+)\)(?=[^()]*$)/)?.[1] || ''; // 匹配最后一对(), 搜索机场时：有2对()：伦敦(盖特维克机场)(LGW)-> LGW  搜索城市有1对()：上海(SHA)-> SHA
			const aCityCode = segment.get('aCityText')?.match(/\(([^()]+)\)(?=[^()]*$)/)?.[1] || '';
			if (dCityCode && aCityCode) return `${dCityCode}-${aCityCode}`;
			return '';
		})
		.toArray()
		.filter(Boolean);
	return cityPairs.join('|');
};

export const sendBlockTrace = ({
	blockType,
	interruptType,
	mainType,
	subType,
}: {
	blockType: BlockTypeValue;
	interruptType: InterruptTypeValue;
	mainType: MainTypeValue;
	subType: SubTypeValue;
}) => {
	try {
		const state = store.getState();
		const flightWay = flightWaySelector(state);
		const mtSegList = getMtSegListSelector(state);
		const cabinType = displayClassGradeSelector(state);
		const passengerData = displayPassengerSelector(state);
		const mtSegListData = mtSegList();
		const ODRoutes = handleODRoutesData(mtSegListData);
		const passengerCount = {
			adult: passengerData.adtCount,
			child: passengerData.chiCount,
			infant: passengerData.infCount,
		};
		const pageId = getPageId(
			window.GlobalSearchCriteria?.scope || '',
			window.GlobalSearchCriteria?.flightWay || ('' as any),
		);
		const basicInfo: BlockBasicInfo = {
			appSource: 'Ctrip',
			platform: 'Online',
			pageId,
			isInternational: getIfScopeDomestic() ? '0' : '1',
			txid: getTransactionIdFromGlobal() || '',
			passengerCount: JSON.stringify(passengerCount),
			tripType: flightWay,
			cabinType: classTypeMap[cabinType]?.join('|') || '',
			ODRoutes,
		};
		echoTrace({
			userBlock: {
				blockType,
				interruptType,
				mainType,
				subType,
				...basicInfo,
			},
			errorCode: EchoBehave.ClientErrorCode.ParseJsonError,
		});
	} catch (e) {
		console.error(e);
		ubtError(e);
	}
};
