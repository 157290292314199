import { EchoBehave, echoTrace } from '@ctrip/xtaro-component-echo-sdk-h5';
import { classTypeMap, InterruptTypeValue, MainTypeValue } from '@/src/utils/types';
import { getTransactionIdFromGlobal } from '../actions/common';
import { ubtError } from '../sources/common/lightUbt';

const pageIdConfig = {
	d_S: '10320673302',
	d_D: '10320673304',
	d_M: '10320673306',
	i_S: '10320672927',
	i_D: '10320672928',
	i_M: '10320672929',
};
const tripTypeMap = {
	S: 'OW',
	D: 'RT',
	M: 'MT',
};

const handleODRoutesData = (mtSegListData) => {
	const cityPairs = mtSegListData
		?.map((segment: any) => {
			const dCityCode = segment.departureCityCode || '';
			const aCityCode = segment.arrivalCityCode || '';
			if (dCityCode && aCityCode) return `${dCityCode}-${aCityCode}`;
			return '';
		})
		.filter(Boolean);
	return cityPairs.join('|');
};

// 适用在首页加载时的基础埋点信息
export const sendBlockTraceInHome = ({
	domestic,
	adtCount = 1,
	chiCount = 0,
	infCount = 0,
	flightWay = 'OW',
	cabinType = 'Y',
	blockType = 'focus',
	subType,
	dCityText,
	aCityText,
}: {
	domestic: boolean;
	adtCount: number;
	chiCount: number;
	infCount: number;
	flightWay: string;
	cabinType: string;
	blockType: 'pop' | 'toast' | 'focus' | 'empty' | 'error';
	subType: string;
	dCityText?: string;
	aCityText?: string;
}) => {
	try {
		const dCityCode = dCityText?.match(/\(([^()]+)\)(?=[^()]*$)/)?.[1] || '';
		const aCityCode = aCityText?.match(/\(([^()]+)\)(?=[^()]*$)/)?.[1] || '';
		const basicInfo = {
			appSource: 'Ctrip',
			platform: 'Online',
			pageId: domestic ? '101023' : '10320669438',
			isInternational: domestic ? '0' : '1',
			txid: getTransactionIdFromGlobal() || '',
			passengerCount: JSON.stringify({
				adult: adtCount,
				child: chiCount,
				infant: infCount,
			}),
			tripType: flightWay,
			cabinType: classTypeMap[cabinType]?.join('|') || '',
			ODRoutes: dCityCode && aCityCode ? `${dCityCode}-${aCityCode}` : '',
		};

		blockType &&
			subType &&
			echoTrace({
				userBlock: {
					blockType,
					interruptType: InterruptTypeValue.Block,
					mainType: MainTypeValue.Search,
					subType,
					...basicInfo,
				},
				errorCode: EchoBehave.ClientErrorCode.ParseJsonError,
			});
	} catch (e) {
		console.error(e);
		ubtError(e);
	}
};

// 适用于首页初始化时，一些组件加载时注册的基础埋点信息，但实际在列表页才引用到的场景
// 不能直接从store上去取（会导致首页白屏），需从GlobalSearchCriteria中获取(首页初始化时 window.GlobalSearchCriteria为undefined。列表页有值)
export const getListBasicInfo = () => {
	const cabinType = window.GlobalSearchCriteria?.cabin || 'Y';
	const flightWay = window.GlobalSearchCriteria?.flightWay || 'S';
	const scope = window.GlobalSearchCriteria?.scope || 'd';
	const ODRoutes = handleODRoutesData(window.GlobalSearchCriteria?.flightSegments || []);
	const basicInfo = {
		appSource: 'Ctrip',
		platform: 'Online',
		pageId: pageIdConfig[`${scope}_${flightWay}`], // 'i-S' 国际单程
		isInternational: scope === 'i' ? '1' : '0',
		txid: getTransactionIdFromGlobal() || '',
		passengerCount: JSON.stringify({
			adult: window.GlobalSearchCriteria?.adultCount || 1,
			child: window.GlobalSearchCriteria?.childCount || 0,
			infant: window.GlobalSearchCriteria?.infantCount || 0,
		}),
		tripType: tripTypeMap[flightWay],
		cabinType: classTypeMap[cabinType]?.join('|') || '',
		ODRoutes,
	};
	return basicInfo;
};

export const sendBlockTraceInList = ({
	blockType = 'focus',
	subType,
}: {
	blockType: 'pop' | 'toast' | 'focus' | 'empty' | 'error';
	subType: string;
}) => {
	console.log('sendBlockTraceInList', 'blockType', blockType, 'subType', subType);
	try {
		const basicInfo = getListBasicInfo();
		blockType &&
			subType &&
			echoTrace({
				userBlock: {
					blockType,
					interruptType: InterruptTypeValue.Block,
					mainType: MainTypeValue.Search,
					subType,
					...basicInfo,
				},
				errorCode: EchoBehave.ClientErrorCode.ParseJsonError,
			});
	} catch (e) {
		console.error(e);
		ubtError(e);
	}
};
