import { getIfScopeDomestic } from '@/src/sources/list/scope';
import { TraceActionValues } from './types';
import { UbtHandler } from '../ubt.v2/handler';
import CONFIG_URL, { searchFlightsUrl } from '../constants/list/api';

const getTraceKey = () => {
	return getIfScopeDomestic()
		? 'O_Flt_Ctrip_Online_Request_Service_State'
		: 'O_Flt_Ctrip_Online_Intl_Request_Service_State';
};

// 页面通过率埋点
export const logListRequestState = ({ url, state, response, action, breakType }) => {
	if (action === TraceActionValues.Break) {
		UbtHandler.onUploadData(getTraceKey(), { url, action: TraceActionValues.Break, pageType: 'list', breakType });
		return;
	}
	let actionType;
	let errorCode;
	if (state === 'success') {
		const { data = {} } = response.data;
		const { flightItineraryList, searchErrorInfo } = data;
		if (searchErrorInfo && searchErrorInfo.errorCode) {
			actionType = TraceActionValues.FetchError;
			errorCode = searchErrorInfo.errorCode;
		} else {
			const noResult = !(flightItineraryList && flightItineraryList.length);
			actionType = noResult ? TraceActionValues.NoResult : TraceActionValues.Success;
		}
	} else {
		actionType = TraceActionValues.NetworkError;
	}

	UbtHandler.onUploadData(getTraceKey(), { url, action: actionType, pageType: 'list', errorCode });
};

export const logRequestState = ({ url, state, response, action, breakType }) => {
	try {
		if (url?.includes(searchFlightsUrl)) {
			logListRequestState({ url: '/search/batchSearch', state, response, action, breakType });
		}
		if (url?.includes(CONFIG_URL.routeSearch)) {
			logListRequestState({ url: '/search/routeSearch', state, response, action, breakType });
		}
	} catch (e) {
		console.error(e);
	}
};
