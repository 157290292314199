import axios from 'axios';
import qs from 'qs';
import { ubtDevTrace } from '@/src/sources/common/lightUbt';
import { eventManager } from '@/src/components/Modal/core/eventManager';
import { handleSpider } from './common/handleSpider';
import { UbtHandler } from '../ubt.v2/handler';
import reportCaptcha from '../ubt.v2/captcha';
import { getIfBuildUpFromUrl } from '../sources/list/getIfBuildUpFromUrl';
import { regWindowEvent } from '../sources/common/regWindowEvent';
import { genUrlFromParams } from '../sources/common/genUrlFromParams';
import { getScope } from '../sources/list/scope';
import { getIfArmy } from '../sources/list/army';
import { FIRST_RENDER_COST_TIME } from '../sources/common/localStorageKey';
import CONFIG_URL, { searchFlightsUrl } from '../constants/list/api';
import { logRequestState } from '../utils/sendRequestStateTrace';
import { performanceTrace } from '../utils/performanceTrace';
import PerformanceMonitor from '../utils/performanceMonitor';
import { getSessionId } from '../utils/getSessionId';
import { TraceActionValues } from '../utils/types';

let firstRequestSearch = true;

const getPathname = (url) => {
	const anchor = document.createElement('a');
	anchor.href = url;
	return anchor.pathname;
};

axios.interceptors.request.use((config) => {
	config.headers = {
		...config.headers,
		'Cache-Control': 'no-cache',
	};
	const pathname = getPathname(config.url);
	config._rawUrl = /search\/pull\//.test(pathname) ? 'pull' : pathname.replace(/.*\//, '');
	config._startAt = +new Date();

	const t = sessionStorage.getItem(FIRST_RENDER_COST_TIME);
	if (!t) {
		let el = document.getElementById('page_start_time');
		if (el?.value) {
			UbtHandler.onUploadData('flt_online_1st_print', { time: config._startAt - el.value });
			sessionStorage.setItem(FIRST_RENDER_COST_TIME, config._startAt - el.value);
		}
	}
	const r = Math.random();
	if (config.url.includes('?')) {
		// https://online.flights.ctrip.fat37.qa.nt.ctripcorp.com/international/search/api/search/routeSearch?v=0.6131723830347837
		// 获取上面这个链接的v值
		const oldv = qs.parse(config.url.split('?')[1] || '')?.v;
		if (oldv) {
			config.url = config.url.replace(`v=${oldv}`, `v=${r}`);
		} else {
			config.url = config.url + '&v=' + r;
		}
	} else {
		config.url = config.url + '?v=' + r;
	}

	if (config.url.includes(searchFlightsUrl)) {
		eventManager.on('Page_Leave', ({ breakType }) => {
			logRequestState({ url: config.url, action: TraceActionValues.Break, breakType });
		});
		// https://online.flights.ctrip.fat3.qa.nt.ctripcorp.com/international/search/api/search/batchSearch
		performanceTrace.trace('beforeFetchFlights');
		if (firstRequestSearch) {
			PerformanceMonitor.mark('batchSearch');
		}
		const { listConstConfig } = window.GlobalConfigs;
		const { CAPTCHA_LOOP_INTERVAL_TIME = 20, CAPTCHA_LOOP_INTERVAL_TIMES = 3 } = listConstConfig || {};

		if (typeof window.signature === 'function') {
			// 初始时就挂载上了
			ubtDevTrace('hotel_captcha_load_when_init', { interval: CAPTCHA_LOOP_INTERVAL_TIME });
			if (firstRequestSearch) {
				ubtDevTrace('hotel_captcha_load_when_init_v2', { interval: CAPTCHA_LOOP_INTERVAL_TIME });
				firstRequestSearch = false;
			}
			return config;
		} else {
			let counter = 0;
			const maxAttempts = CAPTCHA_LOOP_INTERVAL_TIMES;
			const checkSignature = function (resolve, reject) {
				if (typeof window.signature === 'function') {
					// 等待了多少时间后 挂载上的
					ubtDevTrace('hotel_captcha_load_after_try', {
						time: counter * CAPTCHA_LOOP_INTERVAL_TIME,
						counter,
						interval: CAPTCHA_LOOP_INTERVAL_TIME,
					});
					if (firstRequestSearch) {
						ubtDevTrace('hotel_captcha_load_after_try_v2', {
							time: counter * CAPTCHA_LOOP_INTERVAL_TIME,
							counter,
							interval: CAPTCHA_LOOP_INTERVAL_TIME,
						});
						PerformanceMonitor.mark('batchSearch');
						firstRequestSearch = false;
					}
					const token = window.signature(config.data);
					config.headers.token = token;
					resolve(config);
				} else {
					counter++;
					if (counter > maxAttempts) {
						if (firstRequestSearch) {
							ubtDevTrace('hotel_captcha_unload_after_try_v2', { interval: CAPTCHA_LOOP_INTERVAL_TIME });
							PerformanceMonitor.mark('batchSearch');
							firstRequestSearch = false;
						}
						ubtDevTrace('hotel_captcha_unload_after_try', { interval: CAPTCHA_LOOP_INTERVAL_TIME });
						resolve(config);
					} else {
						setTimeout(checkSignature, CAPTCHA_LOOP_INTERVAL_TIME, resolve, reject);
					}
				}
			};

			return new Promise(checkSignature);
		}
	}
	if (config.url.includes(CONFIG_URL.routeSearch)) {
		eventManager.on('Page_Leave', ({ breakType }) => {
			logRequestState({ url: config.url, action: TraceActionValues.Break, breakType });
		});
	}
	return config;
});

axios.interceptors.request.use((config) => {
	const fltOlFpCsConfig = !config.headers.token ? { FltOlFpCs: '1' } : {};
	config.headers = {
		...config.headers,
		...fltOlFpCsConfig,
	};
	return config;
});

const NETWORK_DURATION_MAP = new Map(); // 接口请求耗时

(function () {
	regWindowEvent('beforeunload', () => {
		sessionStorage.setItem(FIRST_RENDER_COST_TIME, '');
		const urls = Array.from(NETWORK_DURATION_MAP.keys());
		if (urls.length) {
			UbtHandler.onUploadData(
				'c_network_stat',
				urls.reduce((prev, url) => {
					const { count, sum } = NETWORK_DURATION_MAP.get(url);
					return Object.assign(prev, { [url]: (sum / count).toFixed(0) });
				}, {})
			);
		}
	});
})();

axios.interceptors.response.use(
	async function (response) {
		if (response.config.url.includes(searchFlightsUrl)) {
			performanceTrace.trace('afterFetchFlights');
			eventManager.off('Page_Leave');
		}
		if (response.config.url.includes(CONFIG_URL.routeSearch)) {
			eventManager.off('Page_Leave');
		}
		const result = await handleSpider(response);
		if (result) {
			return result;
		}
		if (response && response.data) {
			const { data: networkData = {}, request = {}, config = { headers: {} } } = response,
				{ status: siteStatus } = networkData,
				responseWrapper = {
					transactionID: config.headers.transactionID,
					url: request.responseURL,
					method: config.method,
					form: config.data,
					data: networkData,
				};

			if (typeof siteStatus !== 'undefined' && siteStatus !== 0) {
				UbtHandler.onUploadData('c_network_error', responseWrapper);
				logRequestState({ url: request.responseURL, state: 'error' });
			} else {
				const { _rawUrl: url, _startAt: startAt } = response.config;

				if (url && startAt) {
					const duration = +new Date() - startAt;

					if (!NETWORK_DURATION_MAP.has(url)) {
						NETWORK_DURATION_MAP.set(url, {
							count: 0,
							sum: 0,
						});
					}

					const { count, sum } = NETWORK_DURATION_MAP.get(url);
					NETWORK_DURATION_MAP.set(url, {
						count: count + 1,
						sum: sum + duration,
					});
				}
				logRequestState({ url: request.responseURL, state: 'success', response });
			}
		}

		// Error: Failed to read the 'responseText' property from 'XMLHttpRequest': The value is only accessible if the object's 'responseType' is '' or 'text' (was 'json').
		// IE 8-9
		if ((response.data === null || response.data === undefined) && response.config.responseType === 'json') {
			try {
				if (response.request.responseText) response.data = JSON.parse(response.request.responseText);
			} catch (e) {
				// ignored
				UbtHandler.onUploadData('c_network_error_json', e);
				logRequestState({ url: response?.request?.responseURL, state: 'error' });
			}
		}

		return response;
	},
	(error) => {
		UbtHandler.onUploadData('c_network_exception', { error });
		logRequestState({ url: error?.config?.url, state: 'error' });
	}
);

/**
 * 从全局变量直接访问 prevCond 【不建议使用，最好从 store 获取】
 */
export const getPrevCondFromGlobal = () => {
	return window.GlobalSearchCriteria || {};
};

export const getTransactionIdFromGlobal = () => {
	const prevCond = getPrevCondFromGlobal();
	return prevCond.transactionID;
};

/**
 * 从全局变量直接访问 GlobalSwitches
 */
export const getSwitchesFromGlobal = () => {
	return window.GlobalSwitches || {};
};

export const getIfRoundTabModeSwitchFromGlobal = () => {
	const { roundTabModeSwitch } = getSwitchesFromGlobal();

	return getPrevCondFromGlobal().flightWay === 'D' && roundTabModeSwitch && !getIfBuildUpFromUrl();
};

/**
 * 从全局变量直接访问 GlobalConfigs
 */
export const getConfigsFromGlobal = () => {
	return window.GlobalConfigs || {};
};

/**
 * 从全局变量直接访问 GlobalOrderData
 */
export const getOrderDataFromGlobal = () => {
	return window.GlobalOrderData || {};
};

export const setGlobalSearchCriteria = (searchCriteria) => {
	window.GlobalSearchCriteria = searchCriteria;
};
export const setArmyToGlobalSearchCriteria = (army) => {
	if (window.GlobalSearchCriteria) window.GlobalSearchCriteria.army = army;
};

const getLatestExtHeader = (params, url = '') => {
	const signatureParams = url ? url.split('?')[1] : params;
	const token = typeof window.signature === 'function' ? window.signature(signatureParams) : '';
	if (typeof window.signature === 'function' && !token) {
		ubtDevTrace('hotel_captcha_token_failed', { url, params });
	}
	const transactionId = getTransactionIdFromGlobal();
	const transactionIdObject = transactionId ? { transactionID: transactionId } : {};

	const SessionIdObj = {
		Sessionid: getSessionId(),
	};

	const scope = getScope();
	const scopeObject = scope ? { scope } : {};
	const army = getIfArmy();
	const armyObject = army == 1 ? { army } : {};
	const tokenObj = token ? { token } : {};
	return {
		...transactionIdObject,
		...scopeObject,
		...armyObject,
		...tokenObj,
		...SessionIdObj,
		...injectedHeader,
	};
};

let injectedHeader = {};
// 注入额外的 extHeader 信息
export const injectFetchExtHeader = (ext) => {
	injectedHeader = { ...injectedHeader, ...ext };
};

export const fetchGet = (url, params, axiosExtendOps = {}) => {
	return fetchGetWithHeaders(url, params, {}, axiosExtendOps);
};

export const fetchGetWithHeaders = (url, params, headers = {}, axiosExtendOps = {}) => {
	url = genUrlFromParams(url, params);
	reportCaptcha(url);
	const ops = {
		withCredentials: true,
		responseType: 'json',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json;charset=utf-8',
			...getLatestExtHeader(params, url),
			...headers,
		},
	};
	Object.assign(ops, axiosExtendOps);
	return axios.get(url, ops);
};

export const fetchPost = (url, params, axiosExtendOps = {}) => {
	return fetchPostWithHeaders(url, params, {}, axiosExtendOps);
};

export const fetchPostWithHeaders = (url, params, headers = {}, axiosExtendOps = {}) => {
	const ops = {
		withCredentials: true,
		responseType: 'json',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json;charset=utf-8',
			...getLatestExtHeader(params),
			...headers,
		},
		validateStatus: (status) => {
			// 自定义状态码验证逻辑
			return (status >= 200 && status < 300) || status === 432; // 允许 200-299 和 432 状态码
		},
		...axiosExtendOps,
	};
	reportCaptcha(url);
	return axios.post(url, params, ops);
};

export const fetchPostForm = (url, params) => {
	reportCaptcha(url);
	return axios.post(url, params, {
		withCredentials: true,
		responseType: 'json',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
			...getLatestExtHeader(params),
		},
	});
};
